<template>
  <div class="find-password " @click.stop.prevent>
    <span class="go-back" @click="$emit('isOpenReg')"><i class="el-icon-back"></i> 返回</span>
    <div class="user-login" v-show="!enterpriseVisible && !resetVisible">

      <h2>找回密码</h2>
      <div class="input-row">
        <div class="icon">
          <span class="iconfont  icon-PCchawanwu_yanzhengma"></span>
        </div>
        <input type="text" placeholder="请输入密保手机" v-model="accountInfo.phone">
      </div>
      <div class="input-row">
        <div class="icon">
          <span class="iconfont  icon-PCchawanwu_shurumima"></span>
        </div>
        <input type="text" placeholder="请输入六位验证码" v-model="accountInfo.code">
        <a href="javascript:void(0);" @click="getCode">{{codeInfo}}</a>
      </div>
      <div class="input-row">
        <div class="icon">
          <span class="iconfont  icon-PCchawanwu_yanzhengma"></span>
        </div>
        <input type="password" placeholder="请输入密码" v-model="password">
      </div>
      <div class="input-row">
        <div class="icon">
          <span class="iconfont  icon-PCchawanwu_yanzhengma"></span>
        </div>
        <input type="password" placeholder="请再次输入密码" v-model="repeatPassword">
      </div>
      <div class="input-row" style="text-align: center">
        <button @click="resetPassword" class="commit">提交</button>

      </div>
    </div>
  </div>
</template>
<script>
  import {
    checkPassword,
    checkPhone,
    checkCode,
    getCodeTime
  } from "@/util/checkLogin";
  import {forgetPasswordCode, resetPassword} from "@/api/account/employee";

  export default {
    data() {
      return {
        codeInfo: '获取验证码',
        enterpriseVisible: false,
        resetVisible: false,
        accountInfo: {
          phone: '',
          uuid: '',
          code: '',
        },
        enterprises: [],
        sendOk: true,
        tempPhone: '',
        password: '',
        repeatPassword: ''
      };
    },
    methods: {
      /**
       * 返回登录
       */
      //获取验证码
      getCode: function () {
        let _this = this
        if (!_this.sendOk && _this.tempPhone === this.accountInfo.phone) {
          return
        }
        _this.tempPhone = this.accountInfo.phone
        if (this.accountInfo.phone) {
          let checkPhoneObj = checkPhone(this.accountInfo.phone);
          if (!checkPhoneObj.status) {
            this.$message.error(checkPhoneObj.msg);
            return;
          }

          forgetPasswordCode(this.accountInfo.phone).then(res => {
            if (res.code === 0) {
              _this.$message.success('验证码已发送至您的手机，请注意查收');
              _this.sendOk = false
              getCodeTime(function (value) {
                _this.codeInfo = value;
              }, () => {
                _this.sendOk = true
              });
            } else {
              _this.$message.error(res.msg);
            }
          }).catch(e => {

          })
        } else {
          this.$message.error('请先输入手机号');
        }
      },

      resetPassword: function () {
        let check = checkPassword(this.password)
        if (!check.status) {
          this.$message.error(check.msg)
          return
        }

        if (this.password !== this.repeatPassword) {
          this.$message.error('两次密码不一致')
          return
        }

        resetPassword({code: this.accountInfo.code, password: this.password, phone: this.accountInfo.phone}).then(res => {
          if (res.code === 0) {
            this.$message.success('重置成功，请重新登录');
            this.resetVisible = false
            this.password = ''
            this.repeatPassword = ''
            this.$emit("isOpenReg", true)
          } else {
            this.$message.error(res.msg);
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .find-password {
    width: 436px;
    height: 436px;
    background-color: #ffff;
    position: absolute;
    right: 20px;
    /*border-radius: 20px;*/
    /*box-shadow: 20px 0 0 rgba(0, 0, 0, .3);*/
  }

  .list-enterprise {
    text-align: center;
    height: 280px;
    overflow-y: scroll;

    li {
      cursor: pointer;
      line-height: 30px;
      padding: 5px 0;
    }

    li:hover {
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      color: $_theme_color;
    }
  }

  .reset-info {
    cursor: pointer;
    color: #999;
    text-align: center;
    font-size: 12px;
  }

  .reset-info:hover {
    color: $_theme_color;
  }

  .go-back{
    position: absolute;
    top: 0;
    display: inline-block;
    color: #666;
    cursor: pointer;
    z-index: 1;
    margin-left: $margin;
  }

  .go-back:hover{
    color: $_theme_color;
  }

  .user-login {
    width: 436px;
    height: 436px;
    /*box-shadow: 0px 0px 5px rgba(0, 0, 0, .1);*/
    float: right;
    border-left: 1px solid #f2f2f2;
    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;

    h2 {
      text-align: center;
      font-size: 18px;
      color: #6f6f6f;
      font-weight: 700;
      margin-top: 50px;
      margin-bottom: 30px;
    }

    .input-row {
      width: 330px;
      margin: 0 auto;
      padding-left: 35px;
      position: relative;
      margin-bottom: 20px;

      .icon {
        position: absolute;
        font-size: 16px;
        left: 4px;
        line-height: 35px;
        color: $_theme_color;
      }

      input {
        display: block;
        width: 100%;
        border: none;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid $--border_color;
        outline: none;
        color: $--font-02-color;
      }

      a {
        position: absolute;
        font-size: 12px;
        right: 10px;
        top: 10px;
        color: $_theme_color;
      }

      .commit {
        display: block;
        margin: 0 auto;
        width: 250px;
        height: 45px;
        margin: 0 auto;
        border: none;
        outline: none;
        letter-spacing: 5px;
        background: $_theme_color;
        color: #fff;
        font-size: 14px;
        border-radius: 22.5px;
        cursor: pointer;
        margin-top: 32px;
      }


    }

    .tips {
      width: 100%;
      font-size: 12px;
      position: absolute;
      bottom: 10px;
      margin-bottom: 20px;
      text-align: center;
      box-sizing: border-box;
      color: #999;
      padding: 0 25px;

      .register {
        float: left;
        cursor: pointer;
        color: $_theme_color;
        font-weight: 600;
      }

      .info {
        float: right;
      }
    }
  }
</style>


