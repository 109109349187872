import { render, staticRenderFns } from "./findPasswd.vue?vue&type=template&id=dfcfc6ea&scoped=true"
import script from "./findPasswd.vue?vue&type=script&lang=js"
export * from "./findPasswd.vue?vue&type=script&lang=js"
import style0 from "./findPasswd.vue?vue&type=style&index=0&id=dfcfc6ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-co_gj3r3yykdrk7kkdeno2phvnk7q/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfcfc6ea",
  null
  
)

export default component.exports